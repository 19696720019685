export const ordersStatusArr = [
  "Pending",
  "Processing",
  // "Shipped",
  "Delivered",
  "Cancelled",
  // "Refunded",
];

export const orderStatus = {
  PENDING: "Pending",
  CANCELED: "Cancelled",
  DELIVERED: "Delivered",
};
