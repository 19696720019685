export const featuresData = [
  {
    title: "Sell Online",
    description:
      "You will gain benefits of online appearance and customers will be able to buy from anywhere",
  },
  {
    title: "Buy anywhere anytime",
    description:
      "As a customer you will be able to order from any shop you want at anytime while setting in your home",
  },
];
